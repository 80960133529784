<script setup>
    import { RouterLink, RouterView } from "vue-router";
    import axios from 'axios';
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
</style>
<style>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.navbar-hover li RouterLink:hover {
  color: #df4e31;
}
.navbar-hover RouterLink:hover {
  color: whitesmoke;
  font-family: 'Raleway', sans-serif;
}
.railway{
  font-family: 'Raleway', sans-serif;
}
.col-sm-4 img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.competence{
  background-image: url('https://api.brieucthiebaut.com/Cockpit_ProjetPerso/assets/link/49816bf83434349e4e0000dd');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
<template>
        <div class="competence">
            <div class="container text-center">
                <div class="row p-5">
                    <div class="col-sm-4">
                        <img src="https://api.brieucthiebaut.com/Cockpit_ProjetPerso/assets/link/49816c053935359cf7000130" alt="portrait" class="img-fluid rounded">
                    </div>
                    <div class="col-sm-8">
                        <div class="card bg-light bg-opacity-75">
                            <div class="card-body">
                                <h2 class="card-title railway" v-for="(Competence,index) in Competences" :key='index'><span v-if="index===0">{{ Competence.Titre }}</span></h2>
                                <hr class="border border-primary border-2 mt-1">
                                <h3 class="card-title railway text-start ms-4" v-for="(Competence,index) in Competences" :key='index'><span v-if="index===0">{{ Competence.Competence[0] }}</span></h3>
                                <h3 class="card-title railway text-start ms-4" v-for="(Competence,index) in Competences" :key='index'><span v-if="index===0">{{ Competence.Competence[1] }}</span></h3>
                                <h3 class="card-title railway text-start ms-4" v-for="(Competence,index) in Competences" :key='index'><span v-if="index===0">{{ Competence.Competence[2] }}</span></h3>
                                <h3 class="card-title railway text-start ms-4" v-for="(Competence,index) in Competences" :key='index'><span v-if="index===0">{{ Competence.Competence[3] }}</span></h3>
                                <h3 class="card-title railway text-start ms-4" v-for="(Competence,index) in Competences" :key='index'><span v-if="index===0">{{ Competence.Competence[4] }}</span></h3>
                                <RouterLink to="/CV" class="btn btn-primary mb-1 fs-5">En découvrir plus !</RouterLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    export default {
        data(){
            return{
                Cartes:[{
                    Titre: "Mon titre",
                    Sstitre: "Mon sous-titre",
                    Description: "Ma description",
                }]
                } 
                },
                mounted(){
                    axios.get("https://api.brieucthiebaut.com/Cockpit_ProjetPerso/api/content/items/Carte").then(
                        response => this.Cartes = response.data
                        )
                } ,
            data(){
                return{
                    Competences:[{
                        Titre: "Mon titre",
                        Competence :[],
                    }]
                }
            },
            mounted(){
                axios.get("https://api.brieucthiebaut.com/Cockpit_ProjetPerso/api/content/items/Competence").then(
                response => this.Competences = response.data
                ) 
            },
            name: 'CarteCompetence'
        }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
