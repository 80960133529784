<script setup>
    import { RouterLink, RouterView } from "vue-router";
    import axios from 'axios';
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
</style>
<style>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.navbar-hover li RouterLink:hover {
  color: #df4e31;
}
.navbar-hover RouterLink:hover {
  color: whitesmoke;
  font-family: 'Raleway', sans-serif;
}
.railway{
  font-family: 'Raleway', sans-serif;
}
.col-sm-4 img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cv{
  background-image: url('https://api.brieucthiebaut.com/Cockpit_ProjetPerso/assets/link/49816bfd346338c47a00014d');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
<template>
        <div class="cv">
            <div class="container text-center">
                <div class="row p-5">
                    <div class="col-sm-4">
                        <img src="https://api.brieucthiebaut.com/Cockpit_ProjetPerso/assets/link/49816bfa64613789d2000386" alt="Carriere" class="img-fluid rounded">
                    </div>
                    <div class="col-sm-8">
                        <div class="card bg-light bg-opacity-75">
                            <div class="card-body">
                                <h2 class="card-title railway" v-for="(Carte,index) in Cartes" :key='index'><span v-if="index===1">{{ Carte.Titre }}</span></h2>
                                <hr class="border border-primary border-2 mt-1">
                                <p class="card-text railway fs-5" v-for="(Carte,index) in Cartes" :key='index'><span v-if="index===1">{{ Carte.Description }}</span></p>
                                <RouterLink to="/CV" class="btn btn-primary mb-1 fs-5">Mon CV</RouterLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    export default {
        data(){
            return{
                Cartes:[{
                    Titre: "Mon titre",
                    Sstitre: "Mon sous-titre",
                    Description: "Ma description",
                }]
                } 
                },
                mounted(){
                    axios.get("https://api.brieucthiebaut.com/Cockpit_ProjetPerso/api/content/items/Carte").then(
                        response => this.Cartes = response.data
                        )
                } ,
            name: 'CarteCV'
        }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
