<script setup>
    import { RouterLink, RouterView } from "vue-router";
    import axios from "axios";
</script>
<style>
    @import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
    .reseausocial{
        text-decoration: none;
        color: white;
        font-weight: bold;
    }
    .reseausocial:hover{
        text-decoration: none;
        color: red;
        font-weight:600;
    }
</style>

<template>
    <div class="bg-dark text-white pt-5 pb-4">
        <div class="container text-center text-md-left">
            <div class="row text-center text-md-left">
                <div class="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                    <h5 class="text-uppercase mb-4 fw-bold text-warning">Brieuc Thiébaut</h5>
                    <p>Bienvenue sur le site de Brieuc Thiébaut !</p>
                </div>
                <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mt-3">
                    <h5 class="text-uppercase mb-4 fw-bold text-warning">Liens des pages</h5>
                    <p><RouterLink to="/Accueil" class="text-white fw-bold" style="text-decoration: none;">Accueil</RouterLink></p>
                    <p><RouterLink to="/FilmsSeriesFinder" class="text-white fw-bold" style="text-decoration: none;">Films & Series Finder</RouterLink></p>
                    <p><RouterLink to="/CV" class="text-white fw-bold" style="text-decoration: none;">CV</RouterLink></p>
                    <p><RouterLink to="/Portfolio" class="text-white fw-bold" style="text-decoration: none;">Portfolio</RouterLink></p>
                    <p><RouterLink to="/Contact" class="text-white fw-bold" style="text-decoration: none;">Contact</RouterLink></p>
                </div>
                <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                    <h5 class="text-uppercase mb-4 fw-bold text-warning">Réseaux Sociaux</h5>
                    <p>
                        <i class="bi bi-linkedin mr-3"></i> <a class="reseausocial" href="https://be.linkedin.com/in/brieucthiebaut">LinkedIn</a> 
                    </p>
                    <p>
                        <i class="bi bi-instagram mr-3"></i> <a class="reseausocial" href="https://www.instagram.com/framed.by.brieuc/">Instagram</a> 
                    </p>
                </div>
                <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mt-3">
                    <h5 class="text-uppercase mb-4 fw-bold text-warning">Contact</h5>
                    <p>
                        <i class="bi bi-envelope mr-3"></i> <span v-for="(Identifiant,index) in Identifiants" :key='index'><span v-if="index===0">{{ Identifiant.Identifiant }}</span></span>
                    </p>
                    <p>
                        <i class="bi bi-linkedin mr-3"></i> <span v-for="(Identifiant,index) in Identifiants" :key='index'><span v-if="index===2">{{ Identifiant.Identifiant }}</span></span>
                    </p>
                    <p>
                        <i class="bi bi-phone mr-3"></i> <span v-for="(Identifiant,index) in Identifiants" :key='index'><span v-if="index===3">{{ Identifiant.Identifiant }}</span></span>
                    </p>
                    <p>
                        <i class="bi bi-house mr-3"></i> <span v-for="(Identifiant,index) in Identifiants" :key='index'><span v-if="index===1">{{ Identifiant.Identifiant }}</span></span>
                    </p>
                </div>
                <hr class="mb-4">
                <div class="row align-items-center">
                    <div class="col-md-7 col-lg-8">
                        <p>Copyright ©2023 Tous droits réservés par</p>
                        <p>
                            <a href="https//:brieucthiebaut.com" style="text-decoration: none;">
                                <strong class="text-warning">Brieuc Thiébaut</strong>
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    data(){
        return{
            Identifiants:[{
                Identifiant : "Brieuc Thiébaut",
            }]
        }
    },
    mounted(){
        axios.get('https://api.brieucthiebaut.com/Cockpit_ProjetPerso/api/content/items/Identifiant').then(
            response => this.Identifiants = response.data
        )
        },
    }
</script>