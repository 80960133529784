import { createRouter, createWebHistory } from 'vue-router'
import Accueil from '../views/Accueil.vue';
const routes = [
  {
    path: '/Accueil',
    name: 'Accueil',
    component: () => import('../views/Accueil.vue')
  },
  {
    path: '/FilmsSeriesFinder',
    name: 'FilmsSeriesFinder',
    component: () => import('../views/FilmsSeriesFinder.vue')
  },
  {
    path: '/CV',
    name: 'CV',
    component: () => import('../views/CV.vue')
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/Portfolio',
    name: 'Portfolio',
    component: () => import('../views/Portfolio.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
