<template>
  
    <div>
      <CarteCompetence/>
    </div>
    <div>
      <CarteFilmsseries/>
    </div>
    <div>
      <CarteCV/>
    </div>
    <div>
      <CartePortfolio/>
    </div>
    <div>
      <CarteContact/>
    </div>

</template>
<script>
// @ is an alias to /src
import CarteFilmsseries from '@/components/CarteFilmsseries.vue'
import CarteCompetence from '@/components/CarteCompetence.vue'
import CarteCV from '@/components/CarteCV.vue'
import CartePortfolio from '@/components/CartePortfolio.vue'
import CarteContact from '@/components/CarteContact.vue'
export default {
  name: 'Accueil',
  components: {
    CarteCompetence,
    CarteFilmsseries,
    CarteCV,
    CartePortfolio,
    CarteContact
  }
}
</script>
<style>

</style>
